<template>
  <footer class="site-footer">
    <div class="top">
      <div class="inner copy-regular">
        <img v-if="footer?.logo?.url" class="logo" :src="footer?.logo?.url" :alt="footer.logo.alt as string" />
        <div class="address">
          <span class="eyebrow copy-regular-bold">{{ footer?.addressTitle }}</span>
          <address class="address">
            <StructuredText :data="footer?.address" class="structured-text" />
          </address>
        </div>
        <nav id="footer-navigation" class="footer-navigation">
          <ul class="section-links">
            <li v-for="section in footer?.sectionLinks" :key="`section-link-${section.id}`" class="item">
              <span class="eyebrow copy-regular-bold">{{ section.title }}</span>
              <ul class="section-links">
                <li v-for="link in section.link" :key="`section-link-${link.id}`" class="item">
                  <Link :link="link" />
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        <div class="some-langswitch-group">
          <nav aria-label="social media links" class="social-links-nav">
            <ul class="social-links">
              <li v-for="link in footer?.socialLinks" :key="`social-link-${link.id}`" class="item">
                <a :href="link.link?.externalUrl as string" target="_blank">
                  <span class="screenreader-only">{{ link.link?.linkText }}</span>
                  <img class="icon" :src="link?.icon?.url" :alt="link?.icon?.alt as string" />
                </a>
              </li>
            </ul>
          </nav>
          <LangSwitch :key="locale" class="langswitch -footer" :in-footer="true" />
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="inner">
        <small class="copy-small">{{ footer?.information }}</small>
        <ul class="legal-information-links copy-regular">
          <li v-for="link in footer?.legalInformationLinks" :key="`legal-information-link-${link.id}`" class="item">
            <Link :link="link" class="link" />
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { StructuredText } from 'vue-datocms';
import LangSwitch from '~/components/site-header/LangSwitch.vue';
import Link from '~/components/content-modules/components/Link.vue';
import type { GetFooterQuery } from '#gql';

const { locale } = useI18n();
const { localeProperties } = useI18n();

const { data } = await useFetch<GetFooterQuery>('/api/content/footer', {
  query: {
    locale: localeProperties.value.siteLocale,
  },
});

const footer = data.value?.footer;
</script>

<style scoped lang="scss">
.screenreader-only {
  @include hide-visually;
}

.site-footer {
  padding-top: var(--spacing-sm);
  margin-top: var(--spacing-xs);
  width: 100%;
  background-color: var(--color-main-blue-light);
  word-break: unset;

  .inner {
    max-width: var(--site-max-width);
    margin: auto;
    padding: var(--site-inner-padding);
  }

  > .top {
    width: 100%;
    background-color: var(--color-main-blue-light);
    color: var(--color-main-blue);

    > .inner {
      @media (--xs), (--sm) {
        padding-top: 4.5rem;
        padding-bottom: 6rem;

        display: grid;
        gap: var(--spacing-sm);
      }

      @media (--md), (--lg) {
        @include grid-layout();

        padding-top: 5.19rem;
        padding-bottom: 3.81rem;
      }

      > .logo {
        @media (--xs), (--sm) {
          height: 9rem;
        }

        @media (--md), (--lg) {
          height: 12.75rem;
          grid-column: 1 / 7;
        }
      }

      > .address {
        @media (--md), (--lg) {
          grid-column: 7 / 13;
        }
      }

      > .footer-navigation {
        @media (--md), (--lg) {
          grid-column: 13 / 21;
        }

        > .section-links {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: var(--spacing-xs);

          @media (--sm) {
            display: flex;
            gap: var(--spacing-5xl);
          }

          > .item > .section-links > .item {
            margin-top: var(--spacing-4xs);
            hyphens: none;
          }
        }
      }

      > .some-langswitch-group {
        @media (--md), (--lg) {
          grid-column: 22 / -1;
        }

        > .social-links-nav {
          margin-bottom: var(--spacing-3xs);

          > .social-links {
            display: flex;
            flex-wrap: wrap;
            gap: var(--spacing-3xs);

            > .item {
              border-radius: 50%;
              display: grid;
              place-items: center;
              width: 36px;
              height: 36px;
              background: var(--base-color-background);
            }
          }
        }
      }
    }
  }

  > .bottom {
    background-color: var(--color-main-blue);
    color: var(--color-white);
    width: 100%;
    min-height: 3.375rem;

    > .inner {
      display: flex;
      gap: var(--spacing-3xs);

      @media (--xs), (--sm), (--md) {
        padding: var(--spacing-2xs);
        flex-direction: column;
      }

      @media (--lg) {
        justify-content: space-between;
      }

      @media (--lg) {
        padding: var(--spacing-3xs) var(--spacing-2xl) var(--spacing-3xs) var(--spacing-2xl);
        align-items: center;
      }

      > .legal-information-links {
        display: flex;
        gap: var(--spacing-3xs);

        > .item > .link {
          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: unset;
            }
          }

          &:focus-visible {
            color: unset;
          }

          &:active {
            color: unset;
          }
        }
      }
    }
  }
}
</style>
